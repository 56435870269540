import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import PuprLogo from "../../assets/img/pupr.png";

import {
  FooterSection,
  Title, 
  Large,
  Empty,
  AddressText,
  Image,
  WrapperSocIcon
} from "./styles";

const Footer = ({ t }: any) => {
  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={24} xs={24}>
              <Image src={PuprLogo} alt="PUPR - Sanitasi" />
              <Row gutter={12} align="middle" className="m-t-1">
                <Col>
                  <WrapperSocIcon>
                    <a href="https://www.instagram.com/ibm.sanitasi/?hl=en" target="_blank">
                      <SvgIcon
                        src="instagram.svg"
                        width="20px"
                        height="20px"
                      />
                    </a>
                  </WrapperSocIcon>
                </Col>
                <Col>
                  <WrapperSocIcon>
                    <a href="https://www.youtube.com/channel/UClw44wRLIIUq-2KXz9J_Xcg" target="_blank">
                      <SvgIcon
                        src="youtube.svg"
                        width="20px"
                        height="20px"
                      />
                    </a>
                  </WrapperSocIcon>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Title>Laman</Title>
              <Large to="/" left="true">
                Beranda
              </Large>
              <Large left="true" to="/profile">
                Profil
              </Large>
              <Large left="true" to="/berita">
                Berita
              </Large>
              <Large left="true" to="/produk-hukum">
                Produk Hukum
              </Large>
              <Large left="true" to="/faq">
                FAQ
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/infografis">
                Infografis
              </Large>
              <Large left="true" to="/informasi-publik">
                Agenda
              </Large>
              <Large left="true" to="/galeri">
                Galeri
              </Large>
              <Large left="true" to="/kontak">
                Kontak
              </Large>
              <Large left="true" to="/sitemap">
                Sitemap
              </Large>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Title>Alamat</Title>
               <AddressText>Gedung Cipta Karya Lt.7</AddressText>
               <AddressText>Jl. Patimura no 20 Kebayoran Baru, Jakarta Selatan</AddressText>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
