const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/profile"],
    exact: true,
    component: "Profile",
  },
  {
    path: ["/", "/berita"],
    exact: true,
    component: "Berita",
  },
  {
    path: ["/", "/berita/detail"],
    exact: true,
    component: "BeritaDetail",
  },
  {
    path: ["/", "/berita-detail"],
    exact: true,
    component: "BeritaDetail",
  },
  {
    path: ["/", "/infografis"],
    exact: true,
    component: "Infografis",
  },
  {
    path: ["/", "/informasi-publik"],
    exact: true,
    component: "Informasi",
  },
  {
    path: ["/", "/informasi-publik-detail"],
    exact: true,
    component: "InformasiDetail",
  },
  {
    path: ["/", "/produk-hukum"],
    exact: true,
    component: "Produk",
  },
  {
    path: ["/", "/galeri"],
    exact: true,
    component: "Galeri",
  },
  {
    path: ["/", "/galeri-video"],
    exact: true,
    component: "GaleriVideo",
  },
  {
    path: ["/", "/kontak"],
    exact: true,
    component: "Kontak",
  },
  {
    path: ["/", "/sitemap"],
    exact: true,
    component: "Sitemap",
  },
  {
    path: ["/", "/faq"],
    exact: true,
    component: "Faq",
  },
  {
    path: ["*", "*"],
    exact: true,
    component: "NotFound",
  }
];

export default routes;
