import { createGlobalStyle } from "styled-components";
export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;
        outline: none;
        width: 100%;
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: rgb(0 0 0 / 90%);
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }

        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        font-size: 18px;
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }

    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .spacing-vertical{
        margin-top: 80px;
    }

    .spacing-bottom{
        margin-top: 20px;
    }

    .m-t-1{
        margin-top: 10px;
    }

    .m-t-2{
        margin-top: 20px;
    }

    .m-t-3{
        margin-top: 30px;
    }

    .m-b-3{
        margin-bottom: 30px;
    }

    .m-b-5{
        margin-bottom: 50px;
    }

    .m-l-1{
        margin-left: 10px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: #E7E9FF;
        border-radius: 6px !important;
    }

    .ant-image-img{
        object-fit: cover !important;
    }

    .stuck{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        margin: 0 auto;
        background: #FFF;
        transition: all 0.3s ease-out;
        box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
    }

    .img-fit-kontak{
        object-fit: cover;
        height: 400px;
        width:100%;
    }

    .img-fit-kontak-card{
        object-fit: cover;
        height: 300px;
        width:100%;
    }

    .button-monitoring{
        background:#030F6B;
        color: #fff;
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        border: 1px solid #030F6B;
        border-radius: 4px;
        padding: 10px 0;
        cursor: pointer;
        margin-top: 0.625rem;
        max-width: 180px;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 16px 30px rgb(23 31 114 / 20%);
        padding: 10px 20px;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
            border: 1px solid #fbb717;
            background-color: #fbb717;
        }
    }
`;
